/**
 * Theme global.js
 */

function init() {
    'use strict';

    //
}

document.addEventListener('DOMContentLoaded', init);

(function ($) {
    $(document).ready(function () {

        //Create anchor menu based on h2 id's inside .site-content on single-site.
        if ($('.site-content').length > 0) {
            let h2 = $('.site-content h2');
            // Assign IDs to each h2 based on their text content
            h2.each(function () {
                let headingText = $(this).text().trim(); // Get the h2 text
                let id = headingText
                    .toLowerCase() // Convert to lowercase
                    .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
                    .replace(/^-+|-+$/g, ''); // Remove leading/trailing hyphens
                $(this).attr('id', id); // Set the ID
            });

            // Create the anchor menu
            let anchor_menu = '<div class="flex flex-wrap gap-sm">';
            h2.each(function () {
                let title = $(this).text().trim(); // Get the h2 text again
                let headingId = $(this).attr('id'); // Get the generated ID
                anchor_menu +=
                    '<a class="block anchor-link whitespace-nowrap text-sm" href="#' + headingId + '">' + title + '</a>';
            });

            //If section#personnel exists add personnel anchor link to anchor menu
            if ($('#personnel').length > 0) {
                anchor_menu += '<a class="block anchor-link whitespace-nowrap text-sm" href="#personnel">Henkilöstö</a>';
            }
            anchor_menu += '</div>';

            // Prepend the anchor menu
            $('.anchor-cloud').prepend(anchor_menu);
        }

        $('#search').on('click', function () {
            console.log('search');
            $(this).toggleClass('open');
            $('.search-form-wrapper').toggleClass('open closed');
        });

        //Open and close accordion
        $('.accordion .item > span').on('click', function () {
            $(this).toggleClass('open closed');
            $(this).siblings('div').toggleClass('open closed');
        });

        $('#hamburger').on('click', function () {
            $(this).toggleClass('open');
            $('#mobile-menu').toggleClass('open closed');
        });
        if ($('ul.mobile-menu .menu-item-has-children').length) {
            $('ul.mobile-menu .menu-item-has-children > a').append('<i class="plus"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">\n' +
                '<circle cx="16" cy="16" r="16" fill="#F2EEE4"/>\n' +
                '<path d="M20 14L16 18L12 14" stroke="#141414" stroke-width="1.5" stroke-linecap="round"/>\n' +
                '</svg></i>');
        }
        $('ul.mobile-menu i').on('click', function (e) {
            e.preventDefault();
            if (!$(e.target).closest('i').is(this)) return;
            $(this).toggleClass('open');
            $(this).parent().siblings('.sub-menu').slideToggle(150);
        });

        const swiper = new Swiper('.swiper', {
            // Optional parameters
            direction: 'horizontal',
            loop: true,

            // If we need pagination
            pagination: {
                el: '.swiper-pagination',
            },

            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

            infinite: true,
            breakpoints: {
                480: {
                    slidesPerView: 1.4,
                },
                720: {
                    slidesPerView: 2.2,
                },
                1200: {
                    slidesPerView: 3.2,
                    spaceBetween: 16
                }
            }
        });
    });
})(jQuery);

